import React from 'react'
import loader from '../assets/img/loader.gif';
export const Loader = () => {
    return (
        <div className="container-fluid  bg_spans text-center">

            <div className="col-lg-2 offset-lg-5">
                <img src={loader} alt='loader' className='img-fluid'></img>
            </div>
        </div>
    )
}
